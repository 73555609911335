$(document).ready(function () {
    bindBackToTop();
});

function bindBackToTop() {
    $('.back-to-top').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0}, 1000);
    });
}